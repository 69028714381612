import { useState } from 'react';
import { Box, Button, Typography, keyframes } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const LoginAlert = ({ message }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [animation, setAnimation] = useState(slideIn);

  const handleClose = () => {
    setAnimation(slideOut);
    setTimeout(() => setIsVisible(false), 300);
  };

  if (!isVisible) return null;

  return (
    <>
      {/* Oscurecimiento de fondo */}
      <Box 
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 1400, // Asegura que esté detrás del alerta pero sobre otros elementos
        }} onClick={handleClose} /> {/* Opcional: cerrar al hacer clic fuera */}

      {/* Contenedor principal */}
      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 1500,
          pointerEvents: 'none', // Asegura que los clics pasen a través de este contenedor
        }}
      >
        {/* Alerta de login */}
        <Box
          sx={{
            animation: `${animation} 0.3s ease-out forwards`,
            position: 'relative',
            zIndex: 1600,
            width: '90%', // Ajuste del ancho para pantallas pequeñas
            maxWidth: '400px',
            backgroundColor: '#333', // Color de fondo de la alerta
            color: '#fff', // Color del texto
            padding: 2,
            borderRadius: 1,
            boxShadow: '0px 4px 12px rgba(0,0,0,0.5)',
            pointerEvents: 'auto', // Asegura que los clics sean manejados por la alerta
          }}
        >
          <Button onClick={handleClose} sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            minWidth: 'auto',
            padding: '6px',
            borderRadius: '50%',
            color: '#fff',
            backgroundColor: '#f44336', // Color del botón de cierre
            '&:hover': {
              backgroundColor: '#d32f2f', // Color del botón de cierre en hover
            },
          }}>
            <CloseIcon />
          </Button>
          <Typography variant="body1">{message}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/login')}
            sx={{ marginTop: 1, backgroundColor: '#2196f3', '&:hover': { backgroundColor: '#1976d2' } }} // Colores personalizados para el botón de login
          >
            Log In
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LoginAlert;
