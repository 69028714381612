import { Modal, Box, Typography, TextField, Button, IconButton, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const CommentModal = ({ open, onClose, onSubmit, value, onChange, comments, label }) => {
 
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '400px',
        // maxHeight: '80vh',
        maxHeight: '500px',
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        transition: 'all 0.3s ease-in-out', // Añadir transición suave
        "&::-webkit-scrollbar": { // Estilizar la barra de desplazamiento
          width: '8px',
        },
        "&::-webkit-scrollbar-track": {
          background: '#f1f1f1',
        },
        "&::-webkit-scrollbar-thumb": {
          background: '#888',
          borderRadius: '10px',
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: '#555',
        },
      }}>
        <IconButton 
          sx={{ position: 'absolute', top: 8, right: 8 }} 
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          {label}
        </Typography>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          label={label}
          value={value}
          onChange={onChange}
          sx={{ mt: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          sx={{ mt: 2 }}
        >
          Send
        </Button>

        <Divider sx={{ my: 2 }} />

        <Box sx={{
          overflowY: 'auto',
          flexGrow: 1,
          pr: 2 // Padding right to avoid content hiding behind the scrollbar
        }}>
          {comments.map((comment, index) => (
            <Box key={index} mb={2}>
              <Divider />
              <Box display="flex" alignItems="center" mt="0.5rem">
                <img
                  width="30px"
                  height="30px"
                  style={{ borderRadius: "50%" }}
                  src={`${comment.userPicturePath}`}
                  alt={`${comment.userName}`}
                />
                <Box ml="1rem">
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {comment.userName}
                  </Typography>
                  <Typography>
                    {comment.comment}
                  </Typography>
                  <Typography variant="caption">
                    {new Date(comment.createdAt).toLocaleString()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default CommentModal;
