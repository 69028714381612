import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FlexBetween } from "../../components/FlexBetween";
import WidgetWrapper from "../../components/WidgetWrapper";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PostForm from "./postsCreation/PostForm";
import useSnackbar from '../../components/Alert';
import Loader from "../../components/Loader";
import axios from "axios";
import { setPosts, setLoading, setError } from "../../state";
import UserImage from "../../components/UserImage";

// Validation schema
const postSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  price: Yup.number(),
  description: Yup.string().required('Description is required'),
  location: Yup.string().required('Location is required'),
  contact: Yup.string().required('Location is required')
});

// Initial values
const initialValues = {
  title: "",
  price: "",
  description: "",
  location: "",
  contact: ""
};

const MyPostWidget = ({ picturePath }) => {
  const dispatch = useDispatch();
  const [isImage, setIsImage] = useState(false);
  const [image, setImage] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const { palette } = useTheme();
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const loading = useSelector((state) => state.loading);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  // const API_URL = "http://localhost:6001"

  const handlePost = async (values, onSubmitProps) => {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append("userId", _id);
    formData.append("title", values.title);
    formData.append("price", values.price);
    formData.append("description", values.description);
    formData.append("location", values.location);
    formData.append("contact", values.contact);
    if (image) {
      formData.append("picture", image);
      formData.append("picturePath", image.name);
    }

    try {
      const response = await axiosInstance.post(`/api/posts`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 201) {
        const posts = response.data;

        console.log('POST====>',posts);

        dispatch(setPosts({ posts }));
        showSnackbar('Post created successfully!');
        dispatch(setLoading(false));
        onSubmitProps.resetForm();
        setImage(null);
        setShowForm(false);
      } else {
        showSnackbar(response.data.error || "Failed to create post", 'error');
      }
    } catch (error) {
      dispatch(setError(error.toString()));
      showSnackbar('An error occurred while posting.', 'error');
    } finally {
      dispatch(setLoading(false));
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={postSchema}
      onSubmit={handlePost}
      
    >
      {({ setFieldValue }) => (
        <Form>
          <WidgetWrapper
            sx={{
              boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.48)',
              transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              mb: '10px',
              "&:hover": {
                transform: 'scale(1.01)',
                color: palette.primary.light,
                cursor: "pointer",
              },
            }}
          >
            {showForm ? (
              <PostForm
                setFieldValue={setFieldValue}
                isImage={isImage}
                setIsImage={setIsImage}
                image={image}
                setImage={setImage}
                picturePath={picturePath}
                palette={palette}
                isNonMobileScreens={isNonMobileScreens}
              />
            ) : (
              <Box>
                <FlexBetween>
                  <UserImage image={picturePath} />
                  <Typography
                    sx={{ color: palette.primary.main, fontSize: '1.3rem' }}
                  >
                    {true ? "Create new Post" : "Make your first Post"}
                  </Typography>
                  <Button
                    onClick={() => setShowForm(true)}
                    sx={{ color: palette.background.main, backgroundColor: palette.neutral.light, borderRadius: "3rem" }}
                  >
                    Add Post
                  </Button>
                </FlexBetween>
              </Box>
            )}
          </WidgetWrapper>

          {/* Snackbar para mostrar notificaciones */}
          {SnackbarComponent}
        </Form>
      )}
    </Formik>
  );
};

export default MyPostWidget;
