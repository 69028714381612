import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {FlexBetween} from '../FlexBetween';
import DescriptionModal from "./DescriptionModal"; 
import ImageModal from './ImageModal';

const PostImageAndDescription = ({ picturePath, description  }) => {
  const { palette } = useTheme();
  const main = palette.neutral.main;

  // Estado para el modal de descripción an images
  const [isImageModalOpen, setImageModalOpen] = useState(false); 
  const [isDescriptionModalOpen, setDescriptionModalOpen] = useState(false); 


  const handleReadMoreClick = () => {
    setDescriptionModalOpen(true);
  };

  const handleCloseDescriptionModal = () => {
    setDescriptionModalOpen(false);
  };

  const handleOpenImageClick = () => {
    setImageModalOpen(true);
  };

  const handleCloseImageClick = () => {
    setImageModalOpen(false);
  };


  return (
    <FlexBetween width='auto'>
      {picturePath && (
        <Box sx={{ width: '60%', "&:hover": { color: palette.primary.light, cursor: "pointer", opacity: '.3' } }}>
          <img
            width="60%"
            height="100px"
            alt="post"
            style={{ objectFit: 'cover', borderRadius: "0.75rem", marginTop: "0.75rem" }}
            src={`${picturePath}`}
            onClick={handleOpenImageClick}
          />
        </Box>
      )}
      <Box sx={{ width: 'auto' }}>
        <Typography
          color={main}
          variant="body2"
          sx={{ mt: "0.5rem", pl: '.5rem', width: '170px', height: 'auto', position: 'relative' }}
        >
          {description.length > 75 ? (
            <>
              {`${description.slice(0, 75)}...`}
              <Typography
                component="span"
                variant="body2"
                color="primary"
                sx={{ cursor: 'pointer', fontWeight: 'bold', textDecoration: 'underline' }}
                onClick={handleReadMoreClick}
              >
                Read more
              </Typography>
            </>
          ) : (
            description
          )}
        </Typography>
      </Box>

      <DescriptionModal
        open={isDescriptionModalOpen}
        onClose={handleCloseDescriptionModal}
        description={description}
      />

      <ImageModal
        open={isImageModalOpen}
        onClose={handleCloseImageClick}
        image={`${picturePath}`}

      />

    </FlexBetween>
  );
};

export default PostImageAndDescription;
