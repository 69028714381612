import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuhtPage from "./scenes/auth";
import HomePage from "./scenes/homePage";
import UserHomePage from "./scenes/userHomePage";
import ProfilePage from "./scenes/profilePage";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import { themeSettings } from "./theme";
import { RequireAuth } from "./hook/requireAuth";
import NotFoundPage from "./scenes/widgets/404/NotFoundPage";
function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  // const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/login" element={<AuhtPage />} />
            <Route path="/register" element={<AuhtPage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/user/:userId" element={
              <RequireAuth>
                <UserHomePage />
              </RequireAuth>
            } />
            <Route path="/profile/:userId" element={
              <RequireAuth>
                <ProfilePage />
              </RequireAuth>
            } />

            {/* NotFoundPage: Este componente se renderiza cuando una ruta no coincide con ninguna de las rutas definidas.  con path="*" captura todas las rutas no definidas y renderiza el componente NotFoundPage. */}
            <Route path="*" element={<NotFoundPage />} />  {/* Ruta 404 */}
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
