import { PersonAddOutlined, PersonRemoveOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFriends } from "../state";
import { FlexBetween } from "./FlexBetween";
import UserImage from "./UserImage";
import { useState } from "react";
import LoginAlert from "./LoginAlert";
import axios from "axios";

const Friend = ({ friendId, name, subtitle, userPicturePath }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL,})

  // comprobación defensiva 
  const isFriend = user && user.friends && user.friends.find(friend => friend._id === friendId);

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  const patchFriend = async () => {
    try {
      if (!token) {
        setShowLoginAlert(true);
        return;
      }
      const response = await axiosInstance.patch(`/api/users/user/${user._id}/${friendId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = response.data;
      dispatch(setFriends({ friends: data }));
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  const handleProfileClick = () => {
    navigate(`/profile/${friendId}`);
  };


  return (
    <FlexBetween>
      <FlexBetween gap="1rem">
        <UserImage image={userPicturePath} size="40px" />
        <Box
          onClick={handleProfileClick}
          sx={{
            textDecoration: 'underline',
            "&:hover": {
              color: palette.primary.light,
              cursor: "pointer",
            },
          }}
        >
          <Typography
            color={main}
            variant="h5"
            fontWeight="500"
            sx={{
              "&:hover": {
                color: palette.primary.dark,
                cursor: "pointer",
              },
            }}
          >
            {name}
          </Typography>
          <Typography color={medium} fontSize="0.75rem">
            {subtitle}
          </Typography>
        </Box>
      </FlexBetween>
      <IconButton
        onClick={() => patchFriend()}
        sx={{ backgroundColor: primaryLight, p: "0.6rem" }}
      >
        {isFriend ? (
          <PersonRemoveOutlined sx={{ color: primaryDark }} />
        ) : (
          <PersonAddOutlined sx={{ color: primaryDark }} />
        )}
      </IconButton>

      {showLoginAlert && <LoginAlert message="Please log in to add friends." />}
    </FlexBetween>
  );
};

export default Friend;
