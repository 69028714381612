
import { useEffect } from 'react';
import { Typography, useTheme } from "@mui/material";
import { FlexBetween } from "../../components/FlexBetween";
import WidgetWrapper from "../../components/WidgetWrapper";

const AdvertWidget = () => {
  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  useEffect(() => {
    // Load the IFrame Player API code asynchronously.
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Replace the 'player' element with an <iframe> and YouTube player after the API code downloads.
    window.onYouTubeIframeAPIReady = () => {
      new window.YT.Player('player', {
        videoId: 'nuZR4aJRpDY',
        playerVars: {
          autoplay: 1,
          loop: 1,
          playlist: 'nuZR4aJRpDY',
          mute: 1
        }
      });
    };
  }, []);

  return (
    <WidgetWrapper>
      <FlexBetween>
        <Typography color={dark} variant="h5" fontWeight="500">
          Sharing community
        </Typography>
        <Typography color={medium}>Contribution Chanel</Typography>
      </FlexBetween>
        <div id="player" style={{ width: '100%', height: '315px', borderRadius: '0.75rem', margin: '0.75rem 0' }}></div>
      <FlexBetween>
        <Typography color={main}>Contribution Chanel</Typography>
        <Typography color={medium}>freefolife.com</Typography>
      </FlexBetween>
      <Typography color={medium} m="0.5rem 0" fontSize='1.2rem'>
        Your contribution can light the path to a better future for those who need it most. Join us and make a difference with your donation today!
      </Typography>
    </WidgetWrapper>
  );
};

export default AdvertWidget;
