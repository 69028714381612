
import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../../scenes/navbar";
import FriendListWidget from "../../scenes/widgets/FriendListWidget";
import MyPostWidget from "../../scenes/widgets/MyPostWidget";
import PostsWidget from "../../scenes/widgets/PostsWidget";
import UserWidget from "../../scenes/widgets/UserWidget";
import axios from "axios";
import Loader from "../../components/Loader";

const ProfilePage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { userId } = useParams();
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const [searchTerm, setSearchTerm] = useState('');

  const getUser = async () => {
    const axiosInstance = axios.create({baseURL:process.env.REACT_APP_API_URL,})
    // const API_URL = "http://localhost:6001"
    try {
      const response = await axiosInstance.get(`/api/users/user/${userId}`, {
        headers: { 
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}` 
        },
      });
      const data = await response.data;
      setUser(data);
      
    } catch (error) {
      console.error("Error fetching user data:", error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!user) {
        navigate('/404');
      }
    }, 15000); // 20 seconds

    return () => clearTimeout(timer); // Clear timeout if the component unmounts or user is found
  }, [user, navigate]);

  // if (!user) return null;
  if (!user) return <div><Loader /></div>;;

  return (
    <Box sx={{ mt: 8 }}>
      <Navbar setSearchTerm={setSearchTerm} />

      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="2rem"
        justifyContent="center"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          <UserWidget userId={userId} picturePath={user.picturePath} />
          <Box m="2rem 0" />
          <FriendListWidget userId={userId} />
        </Box>

        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
           border='1px solid green'
        >
          <MyPostWidget picturePath={user.picturePath} />

        <Box 
          display='flex'
          flexDirection='row'
          justifyContent='center'
           border='1px solid blue'
         
          >
           <Box sx={{width:'350px'}}>
           <PostsWidget 
              userId={userId} 
              isProfile 
              searchTerm={searchTerm} 
            />
           </Box>
        </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfilePage;

