import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoading, setError, setLogin } from "../../../state";
import Loader from "../../../components/Loader";
import useSnackbar from "../../../components/Alert";
import axios from 'axios';

const loginSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
});

const initialValuesLogin = {
  email: "",
  password: "",
};

const LoginForm = () => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const loading = useSelector((state) => state.loading); 
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,  // Asegúrate de que esta variable está definida
    headers: {
      "Content-Type": "application/json"
    }
  });

  const login = async (values, onSubmitProps) => {
    dispatch(setLoading(true));

    try {
      const loggedInResponse = await axiosInstance.post(`/api/auth/login`, values, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const loggedIn = loggedInResponse.data;

      onSubmitProps.resetForm();
      if (loggedIn) {
        dispatch(setLoading(false));
        dispatch(
          setLogin({
            user: loggedIn.user,
            token: loggedIn.token,
          })
        );
        
        showSnackbar("Login Successful", "success");
        // navigate(`/user/${loggedIn.user._id}`); 
        navigate(`/`); 
      }
    } catch (error) {
      // console.error('Login Error:', error.response ? error.response.data : error.message);
      dispatch(setLoading(false));
      dispatch(setError(error.message));
      showSnackbar("Login Failed", "error");
    }
  };
  

  const handleFormSubmit = async (values, onSubmitProps) => {
    await login(values, onSubmitProps);
  };

  if (loading) {
    return <div><Loader /></div>;
  }

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValuesLogin}
        validationSchema={loginSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            >
              <TextField
                label="* Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={Boolean(touched.email) && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                label="* Password"
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={Boolean(touched.password) && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>

            <Box>
              <Button
                fullWidth
                type="submit"
                sx={{
                  m: "2rem 0",
                  p: "1rem",
                  backgroundColor: palette.primary.main,
                  color: palette.background.alt,
                  "&:hover": { color: palette.primary.dark },
                }}
              >
                LOGIN
              </Button>
              <Typography
                onClick={() => navigate("/register")}
                sx={{
                  textAlign: "center",
                  textDecoration: "underline",
                  color: palette.primary.main,
                  "&:hover": {
                    cursor: "pointer",
                    color: palette.primary.dark,
                  },
                }}
              >
                Don't have an account? Sign Up here.
              </Typography>
            </Box>
          </form>
        )}
      </Formik>

      {SnackbarComponent}
    </>
  );
};

export default LoginForm;










