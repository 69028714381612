import { Box, CircularProgress,} from "@mui/material";

const Loader = () => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            sx={{
                position: 'fixed',  // Asegura que cubra toda la pantalla
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 1300, // Valor superior al drawer y app bar en MUI
                backgroundColor: 'rgba(0, 0, 0, 0.2)' // Color de fondo negro con transparencia
            }}
        >
            <CircularProgress />
        </Box>
    );
}

export default Loader;