import { IconButton, Typography } from "@mui/material";
import { ChatBubbleOutlineOutlined } from "@mui/icons-material";

const CommentButton = ({ commentCount, onClick }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconButton onClick={onClick}>
        <ChatBubbleOutlineOutlined sx={{ fontSize: '1rem'}} />
      </IconButton>
      <Typography>{commentCount}</Typography>
    </div>
  );
};

export default CommentButton;
