import React, { useState, useEffect } from 'react';
import {
  ShareOutlined,
  DeleteOutlined,
  WhatsApp,
  Facebook,
  Twitter,
  Instagram,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  Divider
} from "@mui/material";
import { FlexBetween } from "../../components/FlexBetween";
import Friend from "../../components/Friend";
import WidgetWrapper from "../../components/WidgetWrapper";
import useSnackbar from "../../components/Alert";
import axios from "axios";
import CommentModal from "../../components/PostWidget/CommentModal"; // Importar el modal de comentarios
import LikeButton from "../../components/PostWidget/LikeButton"; // Botón de like
import CommentButton from "../../components/PostWidget/CommentButton"; // Botón de comentario
import MessageButton from "../../components/PostWidget/MessageButton"; // Botón de mensaje
import { useDispatch, useSelector } from "react-redux";
import { setPost, deletePost, setLoading } from "../../state";
import Loader from "../../components/Loader";
import PostTitleAndPrice from '../../components/PostWidget/PostTitleAndPrice';
import PostDetails from '../../components/PostWidget/PostDetails';
import PostImageAndDescription from '../../components/PostWidget/PostImageAndDescription';
import LoginAlert from "../../components/LoginAlert";

const PostWidget = ({
  postId, postUserId, name, title, description, price, location, isFree, picturePath, userPicturePath, likes, comments, contact, showDeleteButton,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const [alertMessage, setAlertMessage] = useState("");
  const loggedInUser = useSelector((state) => state.user);
  const loggedInUserId = loggedInUser ? loggedInUser._id : null;
  const isLiked = loggedInUserId ? Boolean(likes[loggedInUserId]) : false;
  const likeCount = Object.keys(likes).length;
  const loading = useSelector((state) => state.loading); 
  const { palette } = useTheme();
  const primary = palette.primary.main;

  const [isComments, setIsComments] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [isMessage, setIsMessage] = useState(false);
  const [commentsState, setCommentsState] = useState(comments);
  const [newMessage, setNewMessage] = useState("");
  const { showSnackbar } = useSnackbar();
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  // Estado para el número aleatorio y el menú de compartir
  const [randomNumber, setRandomNumber] = useState(0);

  useEffect(() => {
    setRandomNumber(Math.floor(Math.random() * 16) + 5);
  }, []);

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const shareOptions = [
    { icon: <WhatsApp />, label: "WhatsApp", url: "https://wa.me/?text=" },
    { icon: <Facebook />, label: "Facebook", url: "https://www.facebook.com/sharer/sharer.php?u=" },
    { icon: <Twitter />, label: "Twitter", url: "https://twitter.com/intent/tweet?text=" },
    { icon: <Instagram />, label: "Instagram", url: "https://www.instagram.com/" } // Nota: Instagram no soporta compartir vía URL
  ];

  const handleShareOptionClick = (url) => {
    window.open(url + window.location.href, '_blank');
    handleClose();
  };

  const patchLike = async () => {
    if (!token) {
      setAlertMessage("Please log in to like posts.");
      return;
    }

    try {
      const response = await axiosInstance.patch(`/api/posts/${postId}/like`, {
        userId: loggedInUserId
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to like post");
      }

      const updatedPost = response.data;
      dispatch(setPost({ post: updatedPost }));
    } catch (error) {
      console.error("Error liking post:", error);
      setAlertMessage("Failed to like post. Please try again later.");
    }
  };

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleCommentSubmit = async () => {
    if (!token) {
      setAlertMessage("Please log in to comment.");
      return;
    }

    try {
      const response = await axiosInstance.post(
        `/api/posts/comment/${postId}`,
        {
          userId: loggedInUserId,
          comment: newComment
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to post comment");
      }

      const updatedPost = response.data;

      dispatch(setPost({ post: updatedPost }));
      setCommentsState(updatedPost.comments);
      setNewComment("");
    } catch (error) {
      console.error("Error submitting comment:", error);
      setAlertMessage("Failed to post comment. Please try again later.");
    }
  };

  const handleMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleMessageSubmit = async () => {
    if (!token) {
      setAlertMessage("Please log in to send messages.");
      return;
    }

    try {
      const response = await axiosInstance.post(
        `/api/messages/send`,
        {
          senderId: loggedInUserId,
          receiverId: postUserId,
          message: newMessage,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        }
      );

      if (response.status === 200) {
        setNewMessage("");
        showSnackbar("Message sent successfully.");
      } else {
        showSnackbar("Failed to send message. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending message:", error.response ? error.response.data : error.message);
      showSnackbar("Failed to send message. Please try again later.");
    }
  };

  const handleCommentClick = () => {
    if (!token) {
      setAlertMessage("Please log in to comment.");
    } else {
      setIsComments(true);
    }
  };

  const handleMessageClick = () => {
    if (!token) {
      setAlertMessage("Please log in to send messages.");
    } else {
      setIsMessage(true);
    }
  };

  const handleCloseModal = () => {
    setIsComments(false);
    setIsMessage(false);
  };

  const handleDelete = async () => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.delete(`/api/posts/${postId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch(setLoading(false));
        dispatch(deletePost({ postId }));
        showSnackbar("Post deleted successfully.");
      } else {
        showSnackbar("Failed to delete post. Please try again later.");
      }
    } catch (error) {
      console.error("Error deleting post:", error);
      showSnackbar("Failed to delete post. Please try again later.");
    }
  };

  if (loading) {
    return <div><Loader /></div>;
  }

  return (
    <WidgetWrapper
      boxShadow = '1px 1px 4px 1px rgba(0,0,0,0.48)'
    >
      {/* Frinde data & Icons */}
      <Friend
        name={name}
        friendId={postUserId}
        userPicturePath={userPicturePath}
        location={location}
      />

      {/* Mostrar el título completo cuando sea más largo que 20 caracteres */}
      <Box>
          <PostTitleAndPrice
          title={title}
          price={price}
          />
      </Box>

      {/* Mostrar el contacto y addr */}
      <Box>
        <PostDetails
        contact={contact}
        location={location}
        />
      </Box>

      {/* Mostrar la imagen y la description */}
      <Box>
        <PostImageAndDescription
          picturePath={picturePath}
          description={description}
          />
      </Box>
      
      <Divider
        sx={{boxShadow:'1px 1px 4px 1px rgba(0,0,0,0.48)'}}
        />
      
      {/* Mostrar los iconos de commenst likes etc...*/}
      <Box>
        <FlexBetween mt="0.2  5rem" mb='0'>
          <FlexBetween gap="1rem">
            <LikeButton isLiked={isLiked} likeCount={likeCount} onLike={patchLike} />
            <CommentButton commentCount={commentsState.length} onClick={handleCommentClick} />
            <MessageButton onClick={handleMessageClick} />
          </FlexBetween>

          <IconButton 
            sx={{ background: primary, color: 'black', borderRadius: '10px', fontSize: '0.7rem', padding: '0.2rem' }}
            onClick={handleShareClick}
          >
            {randomNumber} <ShareOutlined  sx={{ fontSize: '1rem'}}/>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {shareOptions.map((option, index) => (
              <MenuItem key={index} onClick={() => handleShareOptionClick(option.url)}>
                {option.icon}
                <Typography sx={{ marginLeft: '8px' }}>{option.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
          {showDeleteButton && (
            <IconButton
              onClick={handleDelete}
              sx={{
                background: "red",
                color: "white",
                borderRadius: "10px",
                fontSize: "0.7rem",
                padding: "0.2rem",
              }}
            >
              <DeleteOutlined />
            </IconButton>
          )}
        </FlexBetween>
      </Box>

      <CommentModal
        open={isComments}
        onClose={handleCloseModal}
        onSubmit={handleCommentSubmit}
        value={newComment}
        onChange={handleCommentChange}
        comments={commentsState}
        label="Write a comment..."
      />

      <CommentModal
        open={isMessage}
        onClose={handleCloseModal}
        onSubmit={handleMessageSubmit}
        value={newMessage}
        onChange={handleMessageChange}
        comments={[]} // No pasamos comentarios para el modal de mensajes
        label="Write a message..."
      />
       {showSnackbar}
      {alertMessage && <LoginAlert message={alertMessage} />}
    </WidgetWrapper>
  );
};

export default PostWidget;



