import { IconButton, Typography } from "@mui/material";
import { FavoriteBorderOutlined, FavoriteOutlined } from "@mui/icons-material";

const LikeButton = ({ isLiked, likeCount, onLike }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconButton onClick={onLike}>
        {isLiked ? (
          <FavoriteOutlined  sx={{ fontSize: '1rem'}}/>
        ) : (
          <FavoriteBorderOutlined sx={{ fontSize: '1rem'}}/>
        )}
      </IconButton>
      <Typography>{likeCount}</Typography>
    </div>
  );
};

export default LikeButton;
