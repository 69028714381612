import { Box } from "@mui/material";
import { styled } from "@mui/system";
import LoginAlert from "./LoginAlert";

// Componente css para reutilizar estilos 
export const FlexBetween = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
})


export const AnimatedLoginAlert = styled(LoginAlert)(({ theme }) => ({
    animation: 'slideIn 0.5s ease-out forwards',
    '@keyframes slideIn': {
        '0%': {
            transform: 'translateX(100%)',
        },
        '100%': {
            transform: 'translateX(0)',
        },
    }
}));

// export default FlexBetween;