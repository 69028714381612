// Creacion del estado global 

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  user: null,
  userId: null,
  token: null,
  posts: [],
  loading: false, 
  error: null,
  messages: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;

    },
    deletePost: (state, action) => {
      state.posts = state.posts.filter(post => post._id !== action.payload.postId);
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.userId = action.payload.user.id;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
      state.userId = null;
      state.messages = [];
    },
    setFriends: (state, action) => {
      if (state.user) {
        state.user.friends = action.payload.friends;
      } else {
        console.error("user friends non-existent :(");
      }
    },
    setPosts: (state, action) => {
        if (Array.isArray(action.payload.posts)) {
          state.posts = action.payload.posts;
        } else {
          state.error = 'Data received is not an array';
          state.posts = [];
        }
      },
    setPost: (state, action) => {
      const updatedPosts = state.posts.map((post) => {
        if (post._id === action.payload.post._id) return action.payload.post;
        return post;
      });
      state.posts = updatedPosts;
    },
  },
});

export const { setMessages, setLoading, setError, setMode, setLogin, setLogout, setFriends, setPosts, setPost, deletePost } =
  authSlice.actions;
export default authSlice.reducer;
