import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImageModal = ({ open, onClose, image }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="image-modal-title"
      aria-describedby="image-modal-image"
    >
      <Box sx={{
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        height: 'auto',
        maxWidth: '90vw',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: 3,
        borderColor: 'c0d660'
      }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" margin='0'>
          <Typography id="image-modal-title" variant  ="h6" fontSize='0.9rem' >
            Image
          </Typography>
          <IconButton 
            onClick={onClose}
            sx={{background:'red'}}
            >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box id="image-modal-image" sx={{
           mt: 2, 
           display:'flex',
           alignItems:'center',
           justifyContent:'center'}} >
          <img
            width="500px"
            height="auto"
            alt="post"
            style={{ objectFit: 'cover', borderRadius: "0.75rem" }}
            src={image}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ImageModal;
