import { Typography, useTheme } from "@mui/material";
import {FlexBetween} from "../../components/FlexBetween";
import WidgetWrapper from "../../components/WidgetWrapper";

import React from 'react';

const AdvertWidgetLeft = () => {
  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  return (

    <WidgetWrapper>
    <FlexBetween>
      <Typography color={dark} variant="h5" fontWeight="500">
        Sponsored
      </Typography>
      <Typography color={medium}>Create Ad</Typography>
    </FlexBetween>

      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42581.589516247375!2d16.285860673885757!3d48.16134793875804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da8345696c74f%3A0xbdd7e1f6229eef01!2sCaritas%20Sozial-%20und%20R%C3%BCckkehrberatung!5e0!3m2!1sde!2sat!4v1716020585917!5m2!1sde!2sat"
        width="100%"
        height="250"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Map of Caritas Social and Return Counseling Location"
      ></iframe> */}
    <img
      width="100%"
      height="auto"
      alt="advert"
      // src="http://localhost:3001/assets/cosmetic.jpeg"
      src="https://burst.shopifycdn.com/photos/DIY-beard-balm.jpg?width=1000&format=pjpg&exif=0&iptc=0"
      style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
    />
    <FlexBetween>
      <Typography color={main}>MikaCosmetics</Typography>
      <Typography color={medium}>mikacosmetics.com</Typography>
    </FlexBetween>
    <Typography color={medium} m="0.5rem 0">
    Your contribution can light the path to a better future for those who need it most. Join us and make a difference with your donation today!
    </Typography>
    </WidgetWrapper>
  );
};

export default AdvertWidgetLeft;
