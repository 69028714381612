import React, { useState } from 'react';
import {
  EditOutlined,
  DeleteOutlined,
  AttachFileOutlined,
  GifBoxOutlined,
  ImageOutlined,
  MicOutlined,
  //MoreHorizOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Typography,
  InputBase,
  Button,
  IconButton,
  Collapse
} from "@mui/material";
import { FlexBetween } from "../../../components/FlexBetween";
import Dropzone from "react-dropzone";
import UserImage from "../../../components/UserImage";
import { Field, ErrorMessage } from 'formik';
import useSnackbar from '../../../components/Alert';
import axios from 'axios';
import Loader from '../../../components/Loader';

const PostForm = ({ setFieldValue, isImage, setIsImage, image, setImage, picturePath, palette, isNonMobileScreens }) => {
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [showFormFields, setShowFormFields] = useState(false);
  const [showDropzone, setShowDropzone] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  const [descriptionGenerated, setDescriptionGenerated] = useState(false);
  

  const handleGenerateDescription = async () => {
    if (!image) {
      showSnackbar('Please upload an image first', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('image', image);

    try {
      setLoading(true);
      const response = await axiosInstance.post('/api/posts/generate-description', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Generated Response:', response.data);

      const { title, description } = response.data;
      setFieldValue('title', title || '');
      setFieldValue('description', description || '');
      setLoading(false);
      setShowDescription(true);
      setShowTitle(true); // Show the title field
      setDescriptionGenerated(true);  // Disable the button once the description is generated
    } catch (error) {
      showSnackbar('Error generating title and description', 'error');
      console.error('Error generating title and description:', error.response ? error.response.data : error.message);
      setLoading(false);
    }
  };


  return (
    <>
      <Box sx={{ flexDirection: { display:'flex', xs: 'column', md: 'row' }, gap: { xs: '1rem', md: '1.5rem' }, flexWrap: 'wrap' }}>
        <UserImage image={picturePath} />

        <FlexBetween gap="0.25rem" onClick={() => setShowDropzone(!showDropzone)}>

        <ImageOutlined sx={{fontSize:'1.6rem', color: palette.primary.dark }} />
        <Typography
          color={palette.neutral.mediumMain}
          sx={{ fontSize:'1.4rem', "&:hover": { cursor: "pointer", color: palette.primary.dark, textDecoration:'underline' } }}
          >
          Select Image
        </Typography>
      </FlexBetween>
      </Box>

      <Divider sx={{ margin: "1.25rem 0" }} />
      
      <Collapse in={showDropzone}>
        <Box
          border={`1px solid ${palette.neutral.medium}`}
          borderRadius="5px"
          mt="1rem"
          p="1rem"
        >
          <Dropzone
            acceptedFiles=".jpg,.jpeg,.png, .webp"
            multiple={false}
            onDrop={(acceptedFiles) => {
              if (acceptedFiles.length === 0) {
                showSnackbar('No file was selected or file type is not supported', 'error');
              } else {
                setImage(acceptedFiles[0]);
                setShowFormFields(true);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <FlexBetween>
                <Box
                  {...getRootProps()}
                  border={`2px dashed ${palette.primary.main}`}
                  p="0.1rem 1rem"
                  width="85%"
                  sx={{ "&:hover": { cursor: "pointer" } }}
                >
                  <input {...getInputProps()} />
                  {!image ? (
                    <p>Add Image Here</p>
                  ) : (
                    <FlexBetween>
                      <Typography>{image.name}</Typography>
                      <EditOutlined sx={{
                        width: "2vw",
                        height: "2vw",
                        color: "white",
                        backgroundColor: palette.neutral.light,
                        borderRadius: "50%",
                        padding: '0.2rem'
                      }} />
                    </FlexBetween>
                  )}
                </Box>
                {image && (
                  <IconButton
                    onClick={() => {
                      setImage(null);
                      setShowFormFields(false);
                      setShowDescription(false);
                      setShowTitle(false);
                    }}
                    sx={{ width: "10%" }}
                  >
                    <DeleteOutlined
                      sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        padding: '0.2rem'
                      }}
                    />
                  </IconButton>
                )}
              </FlexBetween>
            )}
          </Dropzone>
        </Box>
      </Collapse>
      <Collapse in={showFormFields}>
      <Button
        onClick={handleGenerateDescription}
        disabled={!image || loading || descriptionGenerated}
        sx={{ color: palette.primary.dark, backgroundColor: palette.neutral.light, borderRadius: "3rem", marginTop: "1rem", marginBottom: '1rem' }}
      >
        {loading ? (
          <>
            Generating... <Loader />
          </>
        ) : (
          'Generate Description'
        )}
      </Button>
    </Collapse>
      {/* <Collapse in={showFormFields}>
        <Button
          onClick={handleGenerateDescription}
          disabled={!image || loading || descriptionGenerated}
          sx={{ color: palette.primary.dark, backgroundColor: palette.neutral.light, borderRadius: "3rem", marginTop: "1rem", marginBottom:'1rem' }}
        >
          {loading ? (
            <>
              Generating... <Loader/>
            </>
          ): (
            'Generate Description'
          )}


        </Button>
      </Collapse> */}

      <Collapse in={showDescription}>
        <Box sx={{ flexBasis: '100%', position: 'relative' }}>
          <Field name="description" as={InputBase} placeholder="Description..."
            sx={{
              width: "100%", minWidth: '300px', backgroundColor: palette.neutral.light, borderRadius: "1rem", padding: "0.5rem 0.5rem"
            }}
            multiline
            rows={4}
          />
          <ErrorMessage name="description" component={Typography}
            sx={{
              position: 'absolute',
              bottom: '-20px',
              left: '0',
              color: 'red',
              fontSize: '0.75rem'
            }}
          />
        </Box>
      </Collapse>


      <Collapse in={showTitle}>
        <FlexBetween sx={{ flexDirection: { xs: 'column', md: 'row' }, gap: { xs: '1rem', md: '1.5rem' }, flexWrap: 'wrap', marginTop: '1rem' }}>
        <Box sx={{ flexBasis: '100%', position: 'relative' }}>
          <Field name="title" as={InputBase} placeholder="Title..."
            sx={{
              width: "100%", minWidth: '300px', backgroundColor: palette.neutral.light, borderRadius: "1rem", padding: "0.5rem 0.5rem"
            }}
          />
          <ErrorMessage name="title" component={Typography}
            sx={{
              position: 'absolute',
              bottom: '-20px',
              left: '0',
              color: 'red',
              fontSize: '0.75rem'
            }}
          />
        </Box>

          <Box sx={{ flexBasis: { xs: '100%', md: '30%' }, position: 'relative' }}>
            <Field name="price" as={InputBase} placeholder="Price" sx={{
              width: '100%', minWidth: '100px', backgroundColor: palette.neutral.light, borderRadius: "1rem", padding: "0.2rem 1rem"
            }} />
            <ErrorMessage name="price" component={Typography}
              sx={{
                position: 'absolute',
                bottom: '-20px',
                left: '0',
                color: 'red',
                fontSize: '0.75rem'
              }}
            />
          </Box>

          <Box sx={{ flexBasis: { xs: '100%', md: '50%' }, position: 'relative' }}>
            <Field name="location" as={InputBase} placeholder="Location..." sx={{
              width: '100%', minWidth: '150px', backgroundColor: palette.neutral.light, borderRadius: "1rem", padding: "0.2rem 1rem"
            }} />
            <ErrorMessage name="location" component={Typography}
              sx={{
                position: 'absolute',
                bottom: '-20px',
                left: '0',
                color: 'red',
                fontSize: '0.75rem'
              }}
            />
          </Box>

          <Box sx={{ flexBasis: { xs: '100%', md: '50%' }, position: 'relative' }}>
            <Field name="contact" as={InputBase} placeholder="Contact..." sx={{
              width: '100%', minWidth: '150px', backgroundColor: palette.neutral.light, borderRadius: "1rem", padding: "0.2rem 1rem"
            }} />
            <ErrorMessage name="contact" component={Typography}
              sx={{
                position: 'absolute',
                bottom: '-20px',
                left: '0',
                color: 'red',
                fontSize: '0.75rem'
              }}
            />
          </Box>
        </FlexBetween>

        <Divider sx={{ margin: "1.25rem 0" }} />
      </Collapse>

      <FlexBetween>
        {isNonMobileScreens && (
          <>
            <FlexBetween gap="0.25rem">
              <GifBoxOutlined sx={{ color: palette.neutral.mediumMain }} />
              <Typography color={palette.neutral.mediumMain}>
                Clip
              </Typography>
            </FlexBetween>

            <FlexBetween gap="0.25rem">
              <AttachFileOutlined sx={{ color: palette.neutral.mediumMain }} />
              <Typography color={palette.neutral.mediumMain}>Attachment</Typography>
            </FlexBetween>

            <FlexBetween gap="0.25rem">
              <MicOutlined sx={{ color: palette.neutral.mediumMain }} />
              <Typography color={palette.neutral.mediumMain}>Audio</Typography>
            </FlexBetween>
          </>
        )}

        <Button tton type="submit" sx={{ color: palette.primary.dark, backgroundColor: palette.neutral.light, borderRadius: "3rem" }}>
          CREATE
        </Button>
      </FlexBetween>

      {/* Snackbar para mostrar notificaciones */}
      {SnackbarComponent}
    </>
  );
};

export default PostForm;
