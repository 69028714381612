import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DescriptionModal = ({ open, onClose, description }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="description-modal-title"
      aria-describedby="description-modal-description"
    >
      <Box sx={{
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
        maxWidth: '300px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: 3,
        borderColor: 'c0d660'
      }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" margin='0'>
          <Typography id="description-modal-title" variant="h6" fontSize='0.9rem' >
            Description
          </Typography>
          <IconButton 
            onClick={onClose}
            sx={{background:'red'}}
            >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography id="description-modal-description" sx={{ mt: 2 }}>
          {description}
        </Typography>
      </Box>
    </Modal>
  );
};

export default DescriptionModal;
