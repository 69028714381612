// Este componente manejará la lógica y el renderizado del campo de búsqueda.
import React from 'react';
import { InputBase, IconButton, useTheme } from '@mui/material';
import { Search, Close } from '@mui/icons-material';
import { FlexBetween } from '../../components/FlexBetween';

const NavBarSearch = ({ searchInput, isSearching, handleSearchChange, handleSearchClick }) => {
  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;

  return (
    <FlexBetween backgroundColor={neutralLight} borderRadius='9px' gap='0' padding='0.1rem .5rem' width='70%'>
      <InputBase 
        placeholder='Search...'
        value={searchInput}
        onChange={handleSearchChange}
      />
      <IconButton onClick={handleSearchClick}>
        {isSearching ? <Close /> : <Search />}
      </IconButton>
    </FlexBetween>
  );
}

export default NavBarSearch;
