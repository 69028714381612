import React, { useState } from "react";
import { Box, TextField, IconButton } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from "react-redux";
import axios from 'axios';

const MessageReply = ({ messageId, senderId }) => {
  const [replyText, setReplyText] = useState("");
  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.user._id);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  // const API_URL = "http://localhost:6001";

  const sendReply = async () => {
    try {
      const response = await axiosInstance.post(`/api/messages/send`, {
        senderId: userId,
        receiverId: senderId,
        message: replyText,
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to send message");
      }

      setReplyText("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <Box 
    mt={2} 
    >
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        value={replyText}
        onChange={(e) => setReplyText(e.target.value)}
        placeholder="Write your reply..."
        InputProps={{
          endAdornment: (
            <IconButton size="small" color="primary" onClick={sendReply}>
              <SendIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

export default MessageReply;
