import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// set the reducer 
import authReducer from './state'
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

// Just redux persist
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/es/integration/react';


const  persitConfig = {key: 'root', storage, version: 1};
const persistedReducer = persistReducer(persitConfig, authReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
   return getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [ 
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER]
      }
    })
  }
})  

const root = ReactDOM.createRoot(document.getElementById('root'));
// if we commente the StrictMode we avoid duplicate logs a the browser console
root.render(
     <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <App />
      </PersistGate>
     </Provider>
);

// root.render(
//   <React.StrictMode>
//      <Provider store={store}>
//       <PersistGate loading={null} persistor={persistStore(store)}>
//         <App />
//       </PersistGate>
//      </Provider>
//   </React.StrictMode>
// );
