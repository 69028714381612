import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {FlexBetween} from '../FlexBetween';

const PostDetails = ({ contact, location }) => {
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const main = palette.neutral.main;

  return (
    <FlexBetween>
      <Box maxWidth='45%' lineHeight='1' fontSize='13px' color={primary} sx={{ m: "0rem" }}>
        <Typography color={main}>
          Contact
        </Typography>
        {contact}
      </Box>

      <Box maxWidth='45%' color={primary} lineHeight='1' fontSize='14px' 
        sx={{ mt: "0rem", fontFamily:'italic'}}>
        <Typography color={main}>
          Addr
        </Typography>
        {location}
      </Box>
    </FlexBetween>
  );
};

export default PostDetails;
