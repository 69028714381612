import React from 'react';
import { Box, Select, MenuItem, FormControl, InputBase } from '@mui/material';
import { AccountCircle, Login as LoginIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../../state';
import { useNavigate } from 'react-router-dom';
import UserImage from '../../components/UserImage';

const NavBarUser = ({ userPicturePath }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const userId = user ? user._id : null;
  const fullName = user ? `${user.firstName} ${user.lastName}` : 'No User';

  const handleLogin = () => {
    navigate('/login');
  };

  const handleProfile = () => {
    if (userId) {
      navigate(`/profile/${userId}`);
    }
  };

  return (
    <FormControl variant='standard'>
      <Select
        value={user ? fullName : ""}
        displayEmpty
        renderValue={(selected) => {
          if (user) {
            return (
              <Box 
                sx={{ display: 'flex', alignItems: 'center' }}>
                  <UserImage image={user.picturePath} size="30px" />
                  <Box>
                  {fullName}
                  </Box>
              </Box>
            );
          } else {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccountCircle sx={{ mr: 1 }} />
                No User
              </Box>
            );
          }
        }}
        sx={{
          backgroundColor: 'neutral.light',
          maxWidth: "200px",
          borderRadius: "0.25rem",
          "& .MuiSvgIcon-root": { pr: "0.25rem", width: "1.5rem" },
          "& .MuiSelect-select:focus": { backgroundColor: 'neutral.light' },
        }}
        input={<InputBase />}
      >
        {user ? [
          <MenuItem key="fullName" value={fullName} onClick={handleProfile}>
            {fullName}
          </MenuItem>,
          <MenuItem key="logout" onClick={() => dispatch(setLogout())}>
            Log Out
            <LogoutIcon sx={{ml:'15px'}}/>
          </MenuItem>
        ] : (
          <MenuItem onClick={handleLogin}>
            Login
            <LoginIcon sx={{ml:'5px'}}/>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

export default NavBarUser;
