import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <Typography variant="h1" color="primary">
        404
      </Typography>
      <Typography variant="h5" color="textSecondary">
        Page Not Found
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoHome} sx={{ mt: 2 }}>
        Go to Home
      </Button>
    </Box>
  );
};

export default NotFoundPage;
