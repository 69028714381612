import { Box, Typography, useTheme } from "@mui/material";
import Friend from "../../components/Friend";
import WidgetWrapper from "../../components/WidgetWrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFriends } from "../../state";
import axios from "axios";

const FriendListWidget = ({ userId }) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const token = useSelector((state) => state.token);
  const friends = useSelector((state) => state.user.friends);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  // const API_URL = "http://localhost:6001";

  const getFriends = async () => {
    try {
      const response = await axiosInstance.get(`/api/users/user/${userId}/friends`, {
        headers: { 
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      });

      const data = response.data;

      if (Array.isArray(data)) {
        dispatch(setFriends({ friends: data }));
      } else {
        console.error('Expected an array of friends, but received:', data);
      }
    } catch (error) {
      console.error('Error fetching friends:', error);
    }
  };

  useEffect(() => {
    getFriends();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Friend List
      </Typography>

      <Box display="flex" flexDirection="column" gap="1.5rem">
        {Array.isArray(friends) ? friends.map((friend, index) => (
          <Friend
            key={friend._id || index} // fallback to index if _id is undefined
            friendId={friend._id}
            name={`${friend.firstName} ${friend.lastName}`}
            subtitle={friend.occupation}
            userPicturePath={friend.picturePath}
          />
        )) : <Typography>No friends to display</Typography>}

      </Box>
    </WidgetWrapper>
  );
};

export default FriendListWidget;
