/*
  PostsWidget para pasar la prop showDeleteButton solo cuando estamos en la página del perfil y el usuario logueado es el dueño del post.
*/
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "../../state";
import PostWidget from "./PostWidget";
import { Box} from "@mui/material";
import axios from 'axios';

const PostsWidget = ({ userId, isProfile = false, searchTerm }) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts);
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user?._id);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  const getPosts = async () => {
    try {
      const response = await axiosInstance.get(`/api/posts/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;

      dispatch(setPosts({ posts: data }));

    } catch (error) {
      console.error("Error fetching posts:", error.response ? error.response.data : error.message);
    }
  };

  const getUserPosts = async () => {
    try {
      const response = await axiosInstance.get(`/api/posts/user/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data;
      dispatch(setPosts({ posts: data }));
    } catch (error) {
      console.error("Error fetching user posts:", error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (isProfile) {
      getUserPosts();
    } else {
      getPosts();
    }
  }, [isProfile]); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredPosts = posts.filter(post => {
    const title = post.title ? post.title.toLowerCase() : '';
    const description = post.description ? post.description.toLowerCase() : '';
    const searchTermLower = searchTerm.toLowerCase();
    return title.includes(searchTermLower) || description.includes(searchTermLower);
  });

  return (
    <>
      {filteredPosts.map(({
          _id,
          userId,
          firstName,
          lastName,
          title,
          description,
          price,
          isFree,
          location,
          contact,
          picturePath,
          userPicturePath,
          likes,
          comments,
        }) => (
          <Box
            key={_id}
            sx={{
              margin: '0',
              marginBottom: '10px',
              borderRadius: '8px',
            }}
          >
            <PostWidget
              postId={_id}
              postUserId={userId}
              name={`${firstName} ${lastName}`}
              title={title}
              price={price}
              isFree={isFree}
              description={description}
              location={location}
              contact={contact}
              picturePath={picturePath}
              userPicturePath={userPicturePath}
              likes={likes}
              comments={comments}
              showDeleteButton={isProfile && loggedInUserId === userId}
            />
          </Box>
        )
      )}
    </>
  );

  // return (
  //   <Grid container spacing={2}>
  //     {filteredPosts.map(({
  //         _id,
  //         userId,
  //         firstName,
  //         lastName,
  //         title,
  //         description,
  //         price,
  //         isFree,
  //         location,
  //         contact,
  //         picturePath,
  //         userPicturePath,
  //         likes,
  //         comments,
  //       }) => (
  //         <Grid item xs={12} sm={6} md={4} key={_id}>
  //           <Box
  //             sx={{
  //               boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.48)',
  //               margin: '0',
  //               borderRadius: '8px',
  //             }}
  //           >
  //             <PostWidget
  //               postId={_id}
  //               postUserId={userId}
  //               name={`${firstName} ${lastName}`}
  //               title={title}
  //               price={price}
  //               isFree={isFree}
  //               description={description}
  //               location={location}
  //               contact={contact}
  //               picturePath={picturePath}
  //               userPicturePath={userPicturePath}
  //               likes={likes}
  //               comments={comments}
  //               showDeleteButton={isProfile && loggedInUserId === userId}
  //             />
  //           </Box>
  //         </Grid>
  //       )
  //     )}
  //   </Grid>
  // );
};

export default PostsWidget;



