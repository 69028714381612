import React from 'react';
import { Box, IconButton, Badge, useTheme } from '@mui/material';
import { Message, Notifications, Help, DarkMode, LightMode } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setMode } from '../../state';
import { FlexBetween } from '../../components/FlexBetween';
import MessagesWidget from "../../components/MessagesWidget";
import { styled } from '@mui/system';

// Estiliza el FlexBetween para responder a cambios de tamaño de pantalla
const ResponsiveFlex = styled(FlexBetween)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',  // Alineación vertical en pantallas pequeñas
  },
}));

const NavBarIcons = ({ handleToggleMessages, anchorEl, handleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const dark = theme.palette.neutral.dark;
  const userId = useSelector((state) => state.user?._id);
  const messages = useSelector((state) => Array.isArray(state.messages) ? state.messages : []);
  const unreadMessagesCount = messages.filter((msg) => !msg.read).length;

  return (
    <ResponsiveFlex gap='1rem'>
      <IconButton onClick={() => dispatch(setMode())}>
        {theme.palette.mode === 'dark' ? (
          <DarkMode sx={{ fontSize: '25px'}} />
        ) : (
          <LightMode sx={{ color: dark, fontSize: '25px'}} />
        )}
      </IconButton>

      <Box>
        <IconButton onClick={handleToggleMessages}>
          <Badge badgeContent={unreadMessagesCount} color="secondary">
            <Message sx={{ fontSize: '25px', cursor: 'pointer' }} />
          </Badge>
        </IconButton>
        {userId && <MessagesWidget userId={userId} anchorEl={anchorEl} handleClose={handleClose} />}
      </Box>

      <IconButton>
        <Notifications sx={{ fontSize: '25px', cursor: 'pointer', '&:hover': { color: dark, cursor: 'pointer' },}} />
      </IconButton>
      
      <IconButton>
        <Help sx={{ fontSize: '25px', cursor: 'pointer'}} />
      </IconButton>
    </ResponsiveFlex>
  );
}

export default NavBarIcons;

