
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import LoginForm from "./loginPage/LoginForm";
import RegisterForm from "./registerPage/RegisterForm";

const AuthPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const location = useLocation();
  const isLogin = location.pathname === "/login";

  return (
    <Box>
      <Box
        width="100%"
        backgroundColor={theme.palette.background.alt}
        p="1rem 6%"
        textAlign="center"
      >
        <Typography fontWeight="bold" fontSize="32px" color="primary">
          Free4 You
        </Typography>
      </Box>

      <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={theme.palette.background.alt}
      >
        <Typography
          fontWeight="500"
          fontSize="1.3rem"
          textAlign="center"
          variant="h5"
          sx={{ mb: "1.5rem" }}
        >
          Welcome to Free4 You. The place you can get all just for free!
        </Typography>
        {isLogin ? <LoginForm /> : <RegisterForm />}
      </Box>
    </Box>
  );
};

export default AuthPage;

