import React from 'react';
import { Tooltip, Typography, useTheme } from '@mui/material';
import { FlexBetween } from "../FlexBetween";

const PostTitleAndPrice = ({ title, price }) => {
  const { palette } = useTheme();
  const secondary = palette.primary.dark;
  const displayPrice = price === 0 || price === null || price === undefined ? 'Free' : `$${price}`;

  return (
    <FlexBetween>
      <Tooltip title={title} arrow>
        <Typography
          color={secondary}
          fontSize="0.8rem"
          fontWeight="bold"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '150px',
          }}
        >
          {title.length > 20 ? `${title.slice(0, 20)}...` : title}
        </Typography>
      </Tooltip>

      <Typography color={secondary} sx={{ fontWeight: displayPrice === 'Free' ? 'bold' : 'normal', color: displayPrice === 'Free' ? 'green' : secondary }}>
        {displayPrice}
      </Typography>
    </FlexBetween>
  );
};

export default PostTitleAndPrice;
