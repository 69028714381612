// import Navbar from "scenes/navbar"; Ojo en producion presenta problema probar nuevamente
import React, { useState } from 'react';
import Navbar from "../../scenes/navbar";
import PostsWidget from "../../scenes/widgets/PostsWidget";
import {Box} from '@mui/material'
import AdvertWidget from "../../scenes/widgets/AdvertWidget";
import AdvertWidgetLeft from "../../scenes/widgets/AdvertWidgetLeft";
import AdvertWidgetRight from "../../scenes/widgets/AdvertWidgetRight";
// import Test from '../widgets/Test';

const HomePage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  // const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  return (
      <Box>
        <Navbar  setSearchTerm={setSearchTerm}/>
          
        <Box
          sx={{
            mt: 7,
            width: "100%",
            padding: "2rem 6%",
            display: { xs: "block", md: "flex" },  // 'flex' en pantallas no móviles, 'block' en móviles
            gap: "0.5rem",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              flexBasis: { md: "20%" },
              display: { xs: "none", md: "block" }  // Oculta este widget en móviles
            }}
          >
            <AdvertWidgetLeft />
          </Box>
          
          <Box
            sx={{
              flexBasis: { md: "auto" },
              mt: { xs: "2rem", md: 0 },
              display: { xs: "block", md: "block" }  // Este se muestra en todos los tamaños
            }}
          >
            <AdvertWidget />
          </Box>

          <Box
            sx={{
              flexBasis: "20%",
              display: { xs: "none", md: "block" }  // Oculta este widget en móviles
            }}
          >
            <AdvertWidgetRight />
          </Box>
        </Box> 

        <Box sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)', xl: 'repeat(6, 1fr)', },
            gap: '10px',
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            margin: 'auto',
            mt: { xs: '2rem', sm: '2rem', md: 0 },
          //   background: '#3333',
            width: '90%',
        }}>
          <PostsWidget searchTerm={searchTerm} />
        </Box> 

      </Box>
  )
}

export default HomePage;








