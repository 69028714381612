import React, {useState} from 'react'
import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "../../scenes/navbar";
import UserWidget from "../../scenes/widgets/UserWidget";
import MyPostWidget from "../../scenes/widgets/MyPostWidget";
import PostsWidget from "../../scenes/widgets/PostsWidget";
import AdvertWidget from "../../scenes/widgets/AdvertWidget";
import FriendListWidget from "../../scenes/widgets/FriendListWidget";


const UserHomePage = () => {
    // const { _id, picturePath } = useSelector((state) => state.user);
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    const user = useSelector((state) => state.user);
    const [searchTerm, setSearchTerm] = useState('');
    // Asegurar que user tiene un objeto válido antes de desestructurar
    const { _id, picturePath } = user ? user : { _id: undefined, picturePath: undefined };

return (
    <Box>
        <Navbar setSearchTerm={setSearchTerm}/>

        <Box
            sx={{
                mt: 12, // Ajusta este valor según la altura de tu Navbar
                }}
                width="100%"
                padding="2rem 6%"
                display={isNonMobileScreens ? "flex" : "block"}
                gap="0.5rem"
                justifyContent="space-between"
            >

            <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
                <UserWidget userId={_id} picturePath={picturePath} />
            </Box>
        
        
            <Box
                flexBasis={isNonMobileScreens ? "auto" : undefined}
                mt={isNonMobileScreens ? undefined : "2rem"}
            >
                <MyPostWidget picturePath={picturePath}/>
                {/* <PostsWidget userId={_id} /> */}

                <Box
                    flexBasis={isNonMobileScreens ? "auto" : undefined}
                    mt={isNonMobileScreens ? undefined : "2rem"}
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' },
                        gap: '20px',
                        overflowWrap: 'break-word',
                        wordBreak: 'break-all',
                        width: '100%', // Asegúrate de que el Box toma todo el ancho disponible
                        mt: { xs: '2rem', sm: '2rem', md: 0 } // Margen superior, diferente para móviles y tabletas/desktops
                        
                    }}
                    >
                    <PostsWidget 
                    searchTerm={searchTerm}
                    userId={_id} 
                    style={{ // Pasar estilos directamente
                        boxShadow: '1px 1px 9px 1px rgba(184,182,184,1)',
                        // padding: '10px',
                        // margin: '5px',
                        // border: '1px solid green',
                        // borderRadius: '5px',
                        // minWidth: 0,
                        // maxWidth: '100%',
                        
                        // // overflow: 'world-wrap',
                        // overflowWrap: 'break-word'
                      }}
                    /> 
                </Box>
            </Box>

            {isNonMobileScreens && (
            <Box flexBasis="20%">
                <AdvertWidget />
            <Box m="2rem 0" />
                <FriendListWidget userId={_id} />
            </Box>
            )}
        </Box> 
        
    </Box>
  )
}

export default UserHomePage



