import React, { useState } from "react";
import { Box, Typography, useTheme, IconButton, Popover, Modal, Avatar } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setMessages } from "../state";
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import MessageReply from './MessagesWidget/MessageReply';
import axios from 'axios';

const MessagesWidget = ({ userId, anchorEl, handleClose }) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const token = useSelector((state) => state.token);
  const messages = useSelector((state) => state.messages || []);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  const deleteMessage = async (messageId) => {
    try {
      const response = await axiosInstance.delete(`/api/messages/${messageId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to delete message");
      }

      dispatch(setMessages(messages.filter(msg => msg._id !== messageId)));
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const markMessageAsRead = async (messageId) => {
    try {
      const response = await axiosInstance.patch(`/api/messages/${messageId}/read`, {}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to mark message as read");
      }

      dispatch(setMessages(messages.map(msg => msg._id === messageId ? { ...msg, read: true } : msg)));
    } catch (error) {
      console.error("Error marking message as read:", error);
    }
  };

  const handleOpenModal = (message) => {
    setSelectedMessage(message);
    setModalOpen(true);
    if (!message.read) {
      markMessageAsRead(message._id);
    }
  };

  const handleCloseModal = () => {
    setSelectedMessage(null);
    setModalOpen(false);
  };

  return (
    <div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            maxHeight: '50vh',
            width: '40ch',
          },
        }}
      >
        <Box p={2}>
        <Typography variant="h6" color={palette.primary.main} sx={{ m: "0.1rem" }}>
          Messages
        </Typography>
        {messages.map((message, index) => (
          <Box key={index} display="flex" alignItems="flex-start" mb={2} p={1} border="1px solid #ddd" borderRadius="8px">
            <Avatar src={message.senderId.picturePath} alt={message.senderId.firstName} />
            <Box ml={2} flexGrow={1}>
              <Typography sx={{ fontWeight: 'bold' }}>
                {message.senderId.firstName} {message.senderId.lastName}
              </Typography>
              <Typography variant="body2">{message.message.slice(0, 25)}...</Typography>
            </Box>
            <Box>

              <Typography variant="caption">{new Date(message.createdAt).toLocaleString()}</Typography>
              
              <Box ml={2} flexGrow={1} sx={{display:'flex', justifyContent:'space-between'}}>

                <IconButton onClick={() => handleOpenModal(message)} size="small">
                  <MoreHorizIcon fontSize="small" />
                </IconButton>

                <IconButton onClick={() => deleteMessage(message._id)} size="small">
                  <DeleteIcon fontSize="small" />
                </IconButton>
                
              </Box>
            </Box>
          </Box>
        ))}
        </Box>

      </Popover>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}>
          <Box display="flex" justifyContent="space-between">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {selectedMessage?.senderId.firstName} {selectedMessage?.senderId.lastName}
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {selectedMessage?.message}
          </Typography>
          {selectedMessage && <MessageReply messageId={selectedMessage._id} senderId={selectedMessage.senderId._id} />}
        </Box>
      </Modal>
    </div>
  );
};

export default MessagesWidget;


