import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Menu, Close } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlexBetween } from '../../components/FlexBetween';
import { Box, IconButton, Typography, useTheme, useMediaQuery } from '@mui/material';

import NavBarUser from './navBarUser';
import NavBarIcons from './navBarIcons';
import { setMessages } from '../../state';
import NavBarSearch from './navBarSearch';

const Navbar = ({ setSearchTerm }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery('(min-width: 1000px)');
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  // const messages = useSelector((state) => Array.isArray(state.messages) ? state.messages : []);
    
  const [searchInput, setSearchInput] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  const dark = theme.palette.neutral.dark;
  const blue = theme.palette.primary.dark;
  const alt = theme.palette.background.alt;
  const background = theme.palette.background.default;

  const userId = user ? user._id : null;

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
    setSearchTerm(e.target.value);

    if (e.target.value) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
  };

  const handleSearchClick = () => {
    if (isSearching) {
      setSearchInput('');
      setSearchTerm('');
      setIsSearching(false);
    }
  };

  const handleToggleMessages = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const getMessages = async () => {
      if (!userId) return;

      try {
        const response = await axiosInstance.get(`/api/messages/user/${userId}`, {
          headers: { 
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}` 
          },
        });

        const data = response.data;
        
        if (response.status !== 200 || !Array.isArray(data)) {
          throw new Error("Failed to fetch messages");
        }

        dispatch(setMessages(data));
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    getMessages();
  }, [userId, dispatch, token, axiosInstance]);

  return (
    <FlexBetween 
      padding='1rem 6%' 
      backgroundColor={alt}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1201,
      }}
    >
      {/* Logo */}
      <FlexBetween gap='1.75rem'>
        <Typography 
          lineHeight='.9'
          fontWeight='bold'
          fontSize='clamp(1.2rem, 1rem, 3.25rem)'
          color={blue}
          onClick={() => navigate('/')}
          sx={{
            '&:hover': {
              color: dark,
              cursor: 'pointer'
            },
          }}
        >
          Free4 You
        </Typography>

        {isNonMobileScreens && (
          <NavBarSearch 
            searchInput={searchInput}
            isSearching={isSearching}
            handleSearchChange={handleSearchChange}
            handleSearchClick={handleSearchClick}
          />
        )}
      </FlexBetween>
      
      {!isNonMobileScreens && (
        <NavBarSearch 
          searchInput={searchInput}
          isSearching={isSearching}
          handleSearchChange={handleSearchChange}
          handleSearchClick={handleSearchClick}
        />
      )}
      
      {/* Humberger Menu  */}
      <FlexBetween width='auto' marginLeft='10px'  display="flex" justifyContent={isNonMobileScreens ? 'flex-start' : 'flex-end'} >
          {isNonMobileScreens ? (
            <>
              <NavBarIcons 
                handleToggleMessages={handleToggleMessages}
                anchorEl={anchorEl}
                handleClose={handleClose}
              />
              <NavBarUser />
            </>
          ) : (
            <IconButton onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Menu />
            </IconButton>
          )}
      </FlexBetween>

      {/* Mobile Menu sidebar  */}
      {!isNonMobileScreens && isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          height="100%"
          zIndex="10"
          maxWidth="500px"
          minWidth="300px"
          backgroundColor={background}
        >
          <Box display="flex" justifyContent="flex-end" p="1rem">
            <IconButton onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}>
              <Close />
            </IconButton>
          </Box>

          <FlexBetween
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="3rem"
          >
            <NavBarIcons 
              handleToggleMessages={handleToggleMessages}
              anchorEl={anchorEl}
              handleClose={handleClose}
            />
            <NavBarUser />
          </FlexBetween>
        </Box>
      )}
    </FlexBetween>
  );
}

export default Navbar;
