import { IconButton } from "@mui/material";
import { MailOutline as MailOutlineIcon } from "@mui/icons-material";

const MessageButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <MailOutlineIcon sx={{ fontSize: '1rem'}} />
    </IconButton>
  );
};

export default MessageButton;
